import { Component, ViewChild, Inject } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { MatDividerModule } from '@angular/material/divider';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ENVIRONMENT } from '../../app.component';
import { NgFor, NgIf, CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { GlobalService } from '../../utils/global.service';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { ModalPassageDetailsComponent } from '../../components/modal-passage-details/modal-passage-details.component';
import { CookieManagerService } from '../../services/TokenService/cookier-manager-service.service';
import { ApiResponse } from '../../dto/ApiResponse';

@Component({
  selector: 'app-dashboard-view',
  standalone: true,
  imports: [
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatDividerModule,
    MatIconModule,
    MatButtonModule,
    NgFor,
    NgIf,
    CommonModule
  ],
  templateUrl: './dashboard-view.component.html',
  styleUrl: './dashboard-view.component.scss'
})

export class DashboardViewComponent {
  saudacao: string;
  dataAtualPorExtenso: string;
  tecnologyIds!: string;
  displayedColumns: string[] = ['plate', 'lane', 'initialTimestamp', 'finalTimestamp', 'obuid', 'isLprDetected', 'buttonShowDetails'];

  passagesCount: number = 0;
  dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(private _liveAnnouncer: LiveAnnouncer, private http: HttpClient, @Inject(ENVIRONMENT) public envConfig: any, public globalService: GlobalService, private dialog: MatDialog, public cookieManagerService: CookieManagerService) {
    this.sort = {} as MatSort;
    const horaAtual = new Date().getHours();
    this.saudacao = this.getSaudacao(horaAtual);
    this.dataAtualPorExtenso = this.getDataAtualPorExtenso();
  }

  ngOnInit(): void {
    this.carregarDadosOnInit();
    this.dataSource.paginator = this.paginator;
  }

  openDialog(dataOnePassage: any): void {
    this.dialog.open(ModalPassageDetailsComponent, {
      data: dataOnePassage
    })
  }

  carregarDadosOnInit(): void {
    const serviceRoute: string = `/equipments/passages/consolidated`;

    const token = this.cookieManagerService.getAuthToken()
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });

    this.http.get<ApiResponse>(
      `${this.envConfig.HaseCloudCoreEndpoint}${serviceRoute}`,
      { headers, observe: 'response' }
    ).subscribe((response) => {
      this.dataSource.data = response.body?.data.items;
      const paginationHeader = response.headers.get('X-pagination');
      if (paginationHeader) {
        const params = new URLSearchParams(paginationHeader.split('?')[1]);

        const itensCountValue = params.get('itensCount');
        this.passagesCount = itensCountValue ? parseInt(itensCountValue) : 0;
      } else {
        console.log('Cabeçalho X-Pagination não encontrado');
      }
    });
  }

  carregarDados(pageNumber: number = 1, pageSize: number = 10): void {
    const serviceRoute: string = `/equipments/passages/consolidated?pageNumber=${(pageNumber + 1)}&pageSize=${pageSize}`;

    const token = this.cookieManagerService.getAuthToken()
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });

    this.http.get<ApiResponse>(
      `${this.envConfig.HaseCloudCoreEndpoint}${serviceRoute}`,
      { headers, observe: 'response' }
    ).subscribe((response) => {
      this.dataSource.data = response.body?.data.items;
      const paginationHeader = response.headers.get('X-pagination');
      if (paginationHeader) {
        const params = new URLSearchParams(paginationHeader.split('?')[1]);

        const itensCountValue = params.get('itensCount');
        this.passagesCount = itensCountValue ? parseInt(itensCountValue) : 0;

      } else {
        console.log('Cabeçalho X-Pagination não encontrado');
      }
    });
  }

  getSaudacao(hora: number): string {
    let saudacao: string;

    if (hora >= 5 && hora < 12) {
      saudacao = 'Bom dia';
    } else if (hora >= 12 && hora < 18) {
      saudacao = 'Boa tarde';
    } else {
      saudacao = 'Boa noite';
    }

    return saudacao;
  }

  getDataAtualPorExtenso(): string {
    const meses = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'];
    const dias = ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'];

    const dataAtual = new Date();
    const dia = dataAtual.getDate();
    const mes = meses[dataAtual.getMonth()];
    const ano = dataAtual.getFullYear();
    const diaDaSemana = dias[dataAtual.getDay()];
    return `${diaDaSemana}, ${dia} de ${mes} de ${ano}`;
  }

  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
}


