import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatFormField, MatInputModule, } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { ENVIRONMENT } from '../../app.component';
import { CookieManagerService } from '../../services/TokenService/cookier-manager-service.service';
import { ApiResponse } from '../../dto/ApiResponse';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { formatDateToAAAAMMDD } from '../../utils/date-format/format';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS, MatDateFormats, MatNativeDateModule } from '@angular/material/core';
import { DatePipe, NgIf } from '@angular/common';


export const MY_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};

@Component({
  selector: 'app-monitoring',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatTableModule,
    MatFormField,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    RouterLink,
    RouterLinkActive,
    RouterOutlet,
    MatProgressSpinnerModule,
    NgIf],
  providers: [
    DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ],
  templateUrl: './monitoring.component.html',
  styleUrl: './monitoring.component.scss'
})
export class MonitoringComponent {
  displayedColumns: string[] = ['name_log', 'action'];
  dataSource = new MatTableDataSource();
  passagesCount: number = 0;
  maxDate = new Date();
  isLoading = true;
  isFilterActive = false;
  dateControl = new FormControl();
  loadingLogs: { [key: string]: boolean } = {};

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(@Inject(DateAdapter) private dateAdapter: DateAdapter<any>, private http: HttpClient, @Inject(ENVIRONMENT) public envConfig: any, public cookieManagerService: CookieManagerService) {
    this.dateAdapter.setLocale('pt-BR');
  }

  ngOnInit(): void {
    this.carregarDadosOnInit();
  }

  onDateChange(event: any) {

    this.isLoading = true;
    this.isFilterActive = false;
    this.dataSource.data = [];
    const selectedDate = event.value;
    const formattedDate = formatDateToAAAAMMDD(selectedDate);

    const serviceRoute: string = `/log/especificLog/${formattedDate}`;

    const token = this.cookieManagerService.getAuthToken()
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });

    this.http.get<ApiResponse>(
      `${this.envConfig.HaseCloudCoreEndpoint}${serviceRoute}`,
      { headers, observe: 'response' }
    ).subscribe(
      (response) => {
        console.log(response.body?.data)
        this.dataSource.data = [`logs/haselogs-${formattedDate}.txt`];
        this.createLinkToDownload(response.body?.data);
        this.passagesCount = 1;
        this.isLoading = false;
        this.isFilterActive = true;
      },
      (error) => {
        this.dataSource.data = [];
        this.isLoading = false;
        this.isFilterActive = true;
      }
    );
  }

  carregarDadosOnInit(): void {
    const serviceRoute: string = '/log/listLogsArchives';

    this.requestLogsFromServer(serviceRoute);
  }

  carregarDados(pageNumber: number = 1, pageSize: number = 10): void {
    const serviceRoute: string = `/log/listLogsArchives?pageNumber=${(pageNumber + 1)}&pageSize=${pageSize}`;

    this.requestLogsFromServer(serviceRoute);
  }

  requestLogsFromServer(route: string) {
    this.isLoading = true;
    const token = this.cookieManagerService.getAuthToken()
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });

    this.http.get<ApiResponse>(
      `${this.envConfig.HaseCloudCoreEndpoint}${route}`,
      { headers, observe: 'response' }
    ).subscribe(
      (response) => {
        console.log(response.body?.data)
        this.dataSource.data = response.body?.data.items;
        const paginationHeader = response.headers.get('X-pagination');
        if (paginationHeader) {
          const params = new URLSearchParams(paginationHeader.split('?')[1]);

          const itensCountValue = params.get('itensCount');
          this.passagesCount = itensCountValue ? parseInt(itensCountValue) : 0;
        } else {
          console.log('Cabeçalho X-Pagination não encontrado');
        }

        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  downloadLogs(name: string) {
    const date = name.substring(14, 22);
    const serviceRoute: string = `/log/especificLog/${date}`;
    this.loadingLogs[name] = true;

    const token = this.cookieManagerService.getAuthToken()
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });

    this.http.get<ApiResponse>(
      `${this.envConfig.HaseCloudCoreEndpoint}${serviceRoute}`,
      { headers, observe: 'response' }
    ).subscribe(
      (response) => {

        const downloadUrl = response.body?.data;

        if (downloadUrl) {
          this.createLinkToDownload(downloadUrl);
        } else {
          console.error('URL de download não encontrada.');
        }
        this.loadingLogs[name] = false;
      },
      (error) => {
        console.error('Erro ao obter o link de download:', error);
        this.loadingLogs[name] = false;
      }
    );
  }

  clearFilter() {
    this.isLoading = true;
    this.dataSource.data = [];
    this.isFilterActive = false;
    this.dateControl.reset();
    this.carregarDadosOnInit();
  }

  createLinkToDownload(downloadUrl: string) {
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.target = '_blank';
    link.download = downloadUrl.split('/').pop() ?? 'download.txt';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
