import { Component, ViewChild, OnInit, Inject } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DialogBoxComponent } from '../../../components/delete-dialog-box/delete-dialog-box.component';
import { CompanyDto } from '../../../dto/company/CompanyBaseDto';
import { ENVIRONMENT } from '../../../app.component';
import { CookieManagerService } from '../../../services/TokenService/cookier-manager-service.service';
import { ApiResponse } from '../../../dto/ApiResponse';

export interface ClientsList {
  companyName: string;
  cnpj: string;
}

@Component({
  selector: 'app-clients-list-view',
  standalone: true,
  imports: [
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    RouterLink,
    RouterLinkActive,
    RouterOutlet
  ],
  templateUrl: './clients-list-view.component.html',
  styleUrl: './clients-list-view.component.scss'
})
export class ClientsListViewComponent implements OnInit {
  displayedColumns: string[] = ['companyName', 'cnpj', 'acoes'];
  dataSource = new MatTableDataSource();
  typeOfUser = "";
  passagesCount: number = 0;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    public dialog: MatDialog,
    private http: HttpClient,
    @Inject(ENVIRONMENT) public envConfig: any,
    public cookieManagerService: CookieManagerService
  ) {
    this.sort = {} as MatSort;
  }

  ngOnInit(): void {
    this.carregarDadosOnInit();
    this.dataSource.paginator = this.paginator;
    this.typeOfUser = this.cookieManagerService.decodedToken?.role;

  }

  carregarDadosOnInit(): void {
    const serviceRoute: string = '/companies';

    const token = this.cookieManagerService.getAuthToken()
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });

    this.http.get<ApiResponse>(
      `${this.envConfig.HaseCloudCoreEndpoint}${serviceRoute}`,
      { headers, observe: 'response' }
    ).subscribe(
      (response) => {
        this.dataSource.data = response.body?.data.items;
        const paginationHeader = response.headers.get('X-pagination');
        if (paginationHeader) {
          const params = new URLSearchParams(paginationHeader.split('?')[1]);

          const itensCountValue = params.get('itensCount');
          this.passagesCount = itensCountValue ? parseInt(itensCountValue) : 0;
        } else {
          console.log('Cabeçalho X-Pagination não encontrado');
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  carregarDados(pageNumber: number = 1, pageSize: number = 10): void {
    const serviceRoute: string = `/companies?pageNumber=${(pageNumber + 1)}&pageSize=${pageSize}`;

    const token = this.cookieManagerService.getAuthToken()
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });

    this.http.get<ApiResponse>(
      `${this.envConfig.HaseCloudCoreEndpoint}${serviceRoute}`,
      { headers, observe: 'response' }
    ).subscribe(
      (response) => {
        this.dataSource.data = response.body?.data.items;
        const paginationHeader = response.headers.get('X-pagination');
        if (paginationHeader) {
          const params = new URLSearchParams(paginationHeader.split('?')[1]);

          const itensCountValue = params.get('itensCount');
          this.passagesCount = itensCountValue ? parseInt(itensCountValue) : 0;
        } else {
          console.log('Cabeçalho X-Pagination não encontrado');
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  openDialogDeleteActionButton(idCompany: string, companyName: string) {
    this.dialog.open(DialogBoxComponent, {
      data: {
        id: idCompany,
        title: "Deletar cliente",
        message: `Deseja deletar o cliente ${companyName}?`,
        confirmMessage: "Cliente deletado com sucesso",
        endpoint: "companies",
        envConfig: this.envConfig,
        updateUserList: this.carregarDados.bind(this)
      }
    });
  }

  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
}
